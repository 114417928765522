/**
 * @file   src\store\actions\userAction.ts
 * @brief  This file is responsible for creating asynchronous api call for user slice.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../utils/apiConfig';
import { IProfileUpdateRequest, IProfileSetupRequest, IProfileEditOtpSentRequest, IUserStatusUpdateRequest, ISchedulerProfileUpdateRequest } from '../../interfaces/userInterface';
import { ICAListRequest } from '../../interfaces/caInterface';
import { UserApi, KKIActionTypes } from '../../utils/apiUrls';
import { buildQueryString } from '../../utils/helper';

// Profile setup api call.
export const setUpProfile = createAsyncThunk('/user/setUpProfile', async (request: IProfileSetupRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.PROFILE_SETUP, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Profile update api call.
export const updateProfile = createAsyncThunk('/user/updateProfile', async (request: IProfileUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.UPDATE_PROFILE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// User logout api call.
export const userLogout = createAsyncThunk('/user/userLogout', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(UserApi.LOGOUT);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get CA profile details api call.
export const getProfile = createAsyncThunk('/user/profile', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(UserApi.GET_PROFILE);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Admin profile setup api call.
export const setUpAdminProfile = createAsyncThunk('/user/setUpAdminProfile', async (request: IProfileSetupRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.PROFILE_ADMIN_SETUP, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Profile update api call for admin user.
export const updateAdminProfile = createAsyncThunk('/user/updateAdminProfile', async (request: IProfileUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.UPDATE_ADMIN_PROFILE, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Edit profile otp sent api call.
export const sendProfileEditOtp = createAsyncThunk('/user/sendProfileEditOtp', async (validateRequest: IProfileEditOtpSentRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${UserApi.PROFILE_EDIT_OTP_DENT}?${buildQueryString(validateRequest)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Get admin profile api call.
export const getAdminProfile = createAsyncThunk('/user/getAdminProfile', async (_, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(UserApi.GET_ADMIN_PROFILE);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Get all CA/scheduler users api call.
export const getAllUsers = createAsyncThunk('/user/getAllUsers', async (request: ICAListRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${UserApi.GET_ALL_USERS}?${buildQueryString(request)}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Get scheduler profile api call.
export const getSchedulerDetails = createAsyncThunk('/user/getSchedulerDetails', async (userId: number, { rejectWithValue }) => {
  try {
    const { data } = await axios.get(`${UserApi.GET_SCHEDULER_DETAILS}/${userId}`);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update Scheduler details api call.
export const updateSchedulerDetails = createAsyncThunk('/user/updateSchedulerDetails', async (request: ISchedulerProfileUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.UPDATE_SCHEDULER_DETAILS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});

// Update scheduler user status api call.
export const activateOrDeactivateScheduler = createAsyncThunk('/user/activateOrDeactivateScheduler', async (request: IUserStatusUpdateRequest, { rejectWithValue }) => {
  try {
    const { data } = await axios.post(UserApi.ACTIVATE_OR_INACATIVATE_SCHEDULER_USERS, request);
    return data;
  } catch (error: any) {
    return rejectWithValue(error.data);
  }
});
// Clear all user states to initial state action.
export const revertAllUserState = createAction(KKIActionTypes.REVERT_ALL_USER_STATE);

/**
 * @file   src\components\AssignCACard.tsx
 * @brief  Component for listing schedule.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */
import Strings from '../assests/strings/Strings.json';
import '../styles/ShiftCard.scss';
import { Card, Col, Row, moment } from './ThirdPartyComponents';
import { IShiftDetailsCard } from '../interfaces/scheduleInterface';
import { DATE_FORMAT_DDD_DD_MMM_YYYY_HHMMA, NUMBER_0 } from '../utils/constants';

const AssignCACard = ({ shiftDetails, caresource }: IShiftDetailsCard) => {
  // Determine what to show for color group based on required values
  const renderOpenSlots = () => {
    const { yellowRequired, blueRequired, redRequired, whiteRequired, yellowCount, blueCount, redCount, whiteCount } = shiftDetails || {};

    // Check if all required values are zero
    if (yellowRequired === NUMBER_0 && blueRequired === NUMBER_0 && redRequired === NUMBER_0) {
      return <span>None</span>;
    }

    return (
      <>
        {yellowRequired !== NUMBER_0 && (
          <span className="openslot yellow">
            {yellowCount || NUMBER_0}/{yellowRequired || NUMBER_0}
          </span>
        )}
        {blueRequired !== NUMBER_0 && (
          <span className="openslot blue">
            {blueCount || NUMBER_0}/{blueRequired || NUMBER_0}
          </span>
        )}
        {redRequired !== NUMBER_0 && (
          <span className="openslot red">
            {redCount || NUMBER_0}/{redRequired || NUMBER_0}
          </span>
        )}
        {whiteRequired !== NUMBER_0 && (
          <span className="openslot white">
            {whiteCount || NUMBER_0}/{whiteRequired || NUMBER_0}
          </span>
        )}
      </>
    );
  };

  return (
    <Col className="shiftcard-main shiftdetails-card">
      {shiftDetails ? (
        <Card className="bg-white">
          <Card.Body className="shift-details">
            <Col className="shift-details-sub mb-0">
              <Row>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Label.ShiftName}
                  <span>{shiftDetails.shiftName}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Schedule.ShiftStart}
                  <span>{moment.utc(shiftDetails.shiftFromTime).format(DATE_FORMAT_DDD_DD_MMM_YYYY_HHMMA)}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Schedule.ShiftEnd}
                  <span>{moment.utc(shiftDetails.shiftToTime).format(DATE_FORMAT_DDD_DD_MMM_YYYY_HHMMA)}</span>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  <div className="cadetails">
                    {Strings.Schedule.CASlots}
                    <span>
                      {' '}
                      {shiftDetails?.assignedCACount}/{shiftDetails?.totalCACount}
                    </span>
                  </div>
                </Col>
                {caresource && (
                  <Col xs={6} lg={4} xl={4} xxl={3} className="bb-0-min-xl">
                    <div className="cadetails">
                      {Strings.Label.CallOuts}
                      <span> {shiftDetails?.callOutCount}</span>
                    </div>
                  </Col>
                )}
                <Col xs={6} lg={4} xl={4} xxl={3} className="openslot-main bb-0-min-xl">
                  {Strings.Schedule.OpenSlot}
                  <label htmlFor="Open slot">{renderOpenSlots()}</label>
                </Col>
                <Col xs={6} lg={4} xl={4} xxl={3} className="bb-0-min-xl">
                  {Strings.Schedule.LDOpenslot}
                  <span>
                    {' '}
                    {shiftDetails?.lightDutyCount}/{shiftDetails?.lightDutyRequired}
                  </span>
                </Col>
                {/* {!caresource && ( */}
                <Col xs={6} lg={4} xl={4} xxl={3}>
                  {Strings.Label.OnCallSlots}
                  <span>
                    {shiftDetails?.onCallTotal}/{shiftDetails?.onCallRequired}
                  </span>
                </Col>
                {/* )} */}
              </Row>
            </Col>
          </Card.Body>
        </Card>
      ) : (
        <div>{Strings.Text.NoDataFound}</div>
      )}
    </Col>
  );
};
export default AssignCACard;

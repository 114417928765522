/**
 * @file   src\App.tsx
 * @brief  This file is responsible for routing components.
 * @date   July, 2024
 * @author ZCO Engineer
 * @copyright (c) 2024, ZCO
 */

import { Router, Routes, Route, lazy, Suspense } from './components/ThirdPartyComponents';
import { CARoutePath, RoutePath, AdminRoutePath, ManagerRoutePath } from './utils/enums';
const Login = lazy(() => import('./containers/Login'));
import ForgotPassword from './containers/ForgotPassword';
import Signup from './containers/Signup';
import SetupProfile from './containers/SetupProfile';
import Layout from './components/Layout';
import OuterLayout from './components/OuterLayout';
const Home = lazy(() => import('./containers/CA/Schedule/Home'));
import ShiftDetails from './containers/CA/Schedule/ShiftDetails';
import CreateSwapRequest from './containers/CA/ManageSwap/CreateSwapRequest';
import ManageSwap from './containers/CA/ManageSwap/ManageSwap';
import ManageLeave from './containers/CA/Leave/ManageLeave';
import Profile from './containers/CA/Profile/Profile';
import EditProfile from './containers/CA/Profile/EditProfile';
import ChangePassword from './containers/CA/Profile/ChangePassword';
const SCHManageSchedeler = lazy(() => import('./containers/Scheduler/Schedule/ManageSchedules'));
import SCHShiftDetails from './containers/Scheduler/Schedule/ShiftDetails';
import SCHAssignCA from './containers/Scheduler/Schedule/AssignCA';
import SCHViewSchedules from './containers/Scheduler/Schedule/ViewSchedules';
import SCHManageCA from './containers/Scheduler/ManageCA/ManageCA';
import SCHCADeails from './containers/Scheduler/ManageCA/CADetails';
import SCHCallOut from './containers/Scheduler/ManageCallOut/ManageCallOutShift';
import SCHCAResource from './containers/Scheduler/ManageCallOut/CAResource';
import SCHManageSwap from './containers/Scheduler/ManageSwap/ManageSwap';
import SCHManageLeave from './containers/Scheduler/ManageLeave/ManageLeave';
import SCHManageHolidays from './containers/Scheduler/ManageHolidays/Holidays';
import SCHManageProfile from './containers/Scheduler/ManageProfile/ViewProfile';
import SCHEditProfile from './containers/Scheduler/ManageProfile/EditProfile';
import MNGRReport from './containers/Manager/Report/AttendanceReport';
import NotFound from './containers/NotFound';
import CAProtectedRoute from './components/ProtectedRouteCA';
import AdminProtectedRoute from './components/ProtectedRouteAdmin';
import MNGRUserList from './containers/Manager/ManageUser/UserList';
import MNGRUserDetails from './containers/Manager/ManageUser/UserDetails';
import TrackLDReason from './containers/Manager/TrackLD/TrackLDList';
import PrivacyPolicy from './containers/PrivacyPolicy';
import Terms from './containers/Terms';
import Loader from './components/Loader';

const App = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path="/*" element={<NotFound />} />
          <Route path={RoutePath.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={RoutePath.TermsAndCondition} element={<Terms />} />
          {/* Auth routes. */}
          <Route element={<OuterLayout />}>
            <Route path={RoutePath.Login} element={<Login />} />
            <Route path={RoutePath.Signup} element={<Signup />} />
            <Route path={RoutePath.SetupProfile} element={<SetupProfile />} />
            <Route path={RoutePath.ForgotPassword} element={<ForgotPassword />} />
          </Route>
          {/* Authorized routes. */}
          <Route element={<Layout />}>
            {/* CA Routes. */}
            <Route
              path={CARoutePath.Home}
              element={
                <CAProtectedRoute>
                  <Home />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.Schedule}
              element={
                <CAProtectedRoute>
                  <Home />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.ShiftDetails}
              element={
                <CAProtectedRoute>
                  <ShiftDetails />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.CreateSwapRequest}
              element={
                <CAProtectedRoute>
                  <CreateSwapRequest />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.ManageSwap}
              element={
                <CAProtectedRoute>
                  <ManageSwap />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.ManageLeave}
              element={
                <CAProtectedRoute>
                  <ManageLeave />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.Profile}
              element={
                <CAProtectedRoute>
                  <Profile />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.EditProfile}
              element={
                <CAProtectedRoute>
                  <EditProfile />
                </CAProtectedRoute>
              }
            />
            <Route
              path={CARoutePath.ChangePassword}
              element={
                <CAProtectedRoute>
                  <ChangePassword />
                </CAProtectedRoute>
              }
            />

            {/* Scheduler routes. */}
            <Route
              path={AdminRoutePath.ManageSchedule}
              element={
                <AdminProtectedRoute>
                  <SCHManageSchedeler />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHShiftDetails}
              element={
                <AdminProtectedRoute>
                  <SCHShiftDetails />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHShiftDetailsByCAId}
              element={
                <AdminProtectedRoute>
                  <SCHShiftDetails />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHAssignCA}
              element={
                <AdminProtectedRoute>
                  <SCHAssignCA />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHAssignCAByCAId}
              element={
                <AdminProtectedRoute>
                  <SCHAssignCA />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHViewSchedules}
              element={
                <AdminProtectedRoute>
                  <SCHViewSchedules />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHCASchedules}
              element={
                <AdminProtectedRoute>
                  <SCHViewSchedules />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHManageCA}
              element={
                <AdminProtectedRoute>
                  <SCHManageCA />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHCADetails}
              element={
                <AdminProtectedRoute>
                  <SCHCADeails />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHCallOut}
              element={
                <AdminProtectedRoute>
                  <SCHCallOut />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHCAResource}
              element={
                <AdminProtectedRoute>
                  <SCHCAResource />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHManageSwap}
              element={
                <AdminProtectedRoute>
                  <SCHManageSwap />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHManageLeave}
              element={
                <AdminProtectedRoute>
                  <SCHManageLeave />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHManageHolidays}
              element={
                <AdminProtectedRoute>
                  <SCHManageHolidays />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHManageProfile}
              element={
                <AdminProtectedRoute>
                  <SCHManageProfile />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.SCHEditProfile}
              element={
                <AdminProtectedRoute>
                  <SCHEditProfile />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={AdminRoutePath.ChangePassword}
              element={
                <AdminProtectedRoute>
                  <ChangePassword />
                </AdminProtectedRoute>
              }
            />
            {/* Manager Routes. */}
            <Route
              path={ManagerRoutePath.MNGRReport}
              element={
                <AdminProtectedRoute>
                  <MNGRReport />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={ManagerRoutePath.MNGRUserList}
              element={
                <AdminProtectedRoute>
                  <MNGRUserList />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={ManagerRoutePath.MNGRUserDetails}
              element={
                <AdminProtectedRoute>
                  <MNGRUserDetails />
                </AdminProtectedRoute>
              }
            />
            <Route
              path={ManagerRoutePath.TrackLDReason}
              element={
                <AdminProtectedRoute>
                  <TrackLDReason />
                </AdminProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
